/*
 * Copyright © Ergonode Sp. z o.o. All rights reserved.
 * See LICENSE for license details.
 */
import state from './state';

export default {
    namespaced: true,
    state,
};
